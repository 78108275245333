// ** Initial State
const initialState = {
    allData: [],
    allJobReminderData: [],
    allJobReminderHoursData: [],
    data: [],
    total: 1,
    params: {},
    selectedApprovalMatrix: null,
    addNew: null
  }
  
  const OMJobReminderReducer = (state = initialState, action) => {
    switch (action.type) {
      default:
        return { ...state }
    }
  }
  export default OMJobReminderReducer