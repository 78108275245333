// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import site from './site'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import clientServices from '@src/views/masters/client_service/store/reducer'
import labelMasters from '@src/views/masters/label_master/store/reducer'
import userMasters from '@src/views/masters/user_master/store/reducer'
import assetFamilyMasters from '@src/views/masters/asset/asset_family/store/reducer'
import assetModelMasters from '@src/views/masters/asset/asset_model/store/reducer'
import clientDepartmentMasters from '@src/views/masters/client_department/store/reducer'
import clientBuildingMasters from '@src/views/facility/client_building/store/reducer'
import clientBranchMasters from '@src/views/facility/client_branch/store/reducer'
import clientFloorMasters from '@src/views/facility/client_floor/store/reducer'
import clientZoneMasters from '@src/views/facility/client_zone/store/reducer'
import clientRoomMasters from '@src/views/facility/client_room/store/reducer'
import ServiceTicketFeedbackReducer from '@src/views/service_ticket_feedback/store/reducer'
import vendorMasters from '@src/views/masters/vendor/store/reducer'
import asset from '@src/views/assets/store/reducer'
import DashboardReducer from '@src/views/dashboard/store/reducer'
import serviceTicket from '@src/views/servicetickets/store/reducer'
import orderManagement from '@src/views/order_management/store/reducer'
import { DataTableReducer } from 'react-redux-datatable'
import { ListAssetCertificationReducer, ListAssetComponentCategoryReducer, 
  ListAssetComponentNoteCategoryReducer, ListAssetComponentUnitReducer, ListAssetDocumentReducer, 
  ListAssetFunctionalityReducer, ListAssetIncidentInspectionCategoryReducer, 
  ListAssetIncidentInspectionDegreeReducer, 
  ListAssetIncidentInspectionTypeReducer, ListAssetLSMNoteCategoryReducer, 
  ListAssetMakeModelPriorityReducer, ListServiceTicketPriorityReducer, ListServiceTicketRootCauseReducer, ListServiceTicketTechnicianReducer,
  ListAssetMakeModelRiskReducer, ListAssetMakeModelSegmentReducer, ListAssetManufacturerReducer, 
  ListAssetModalityReducer, ListAssetTaskCategoryReducer, 
  ListAssetTaskStatusReducer, ListAssetTypeReducer, ListRoomTypeReducer, ListAssetVendorServiceReducer, 
  ListAssetContactReducer, ListAssetLocationReducer, ListAssetStateReducer, ListServiceTypeReducer, ListServiceTicketReminderReducer, ListAssetSpecificationReducer, ListInsuranceTypeReducer } from '@src/views/masters/list_master/store/reducer'
  import { ListAssetMainCategoryReducer, ListAssetSubCategoryReducer } from '@src/views/masters/client_category/store/reducer'
  import { ListRoomFittingFurnitureFamilyReducer, ListRoomFittingFurnitureCategoryReducer, ListRoomMainCategoryReducer, ListRoomSubCategoryReducer } from '@src/views/masters/client_facility/store/reducer'
  import PublicLeafReducer from '@src/views/assetpublicleaf/store/reducer'
  import PublicRoomReducer from '@src/views/publicroom/store/reducer'

  import { OMJobPriorityReducer, OMTermConditionReducer, OMPaymentTypeReducer, OMJobMainCategoryReducer, OMJobSubCategoryReducer, OMBillingCycleReducer, OMSgstCgstReducer, OMIgstReducer, OMApprovalMatrixReducer, OMComparisonFactorReducer, OMGoodServiceReceiptConditionReducer, OMPaymentMethodReducer, OMJobCompletionStatusReducer, OMJobOverallStatusReducer, OMJobReminderReducer } from '@src/views/masters/order_management/store/reducer'  

const rootReducer = combineReducers({
  auth,
  site,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  clientServices,
  labelMasters,
  userMasters,
  DashboardReducer,
  ListRoomMainCategoryReducer,
  ListRoomSubCategoryReducer,
  ListRoomFittingFurnitureFamilyReducer,
  ListRoomFittingFurnitureCategoryReducer,
  ListAssetMainCategoryReducer, 
  ListAssetCertificationReducer, 
  ListAssetComponentCategoryReducer, 
  ListAssetComponentNoteCategoryReducer, 
  ListAssetComponentUnitReducer, 
  ListAssetSpecificationReducer, 
  ListAssetDocumentReducer, 
  ListAssetFunctionalityReducer, 
  ListAssetIncidentInspectionCategoryReducer, 
  ListAssetIncidentInspectionDegreeReducer, 
  ListAssetIncidentInspectionTypeReducer, 
  ListAssetLSMNoteCategoryReducer, 
  ListAssetMakeModelPriorityReducer, 
  ListAssetMakeModelRiskReducer, 
  ListAssetMakeModelSegmentReducer, 
  ListServiceTicketPriorityReducer, 
  ListServiceTicketRootCauseReducer, 
  ListServiceTicketTechnicianReducer, 
  ListAssetManufacturerReducer, 
  ListAssetModalityReducer, 
  ListAssetSubCategoryReducer, 
  ListAssetTaskCategoryReducer, 
  ListAssetTaskStatusReducer, 
  ListAssetTypeReducer, 
  ListRoomTypeReducer, 
  ListAssetVendorServiceReducer, 
  ListAssetContactReducer, 
  DataTableReducer,
  assetFamilyMasters,
  assetModelMasters,
  ListAssetLocationReducer,
  ListServiceTypeReducer,
  ListServiceTicketReminderReducer, 
  ListAssetStateReducer,
  ListInsuranceTypeReducer,
  vendorMasters,
  clientDepartmentMasters,
  clientBranchMasters,
  clientFloorMasters,
  clientZoneMasters,
  clientRoomMasters,
  clientBuildingMasters,
  PublicLeafReducer,
  PublicRoomReducer,
  ServiceTicketFeedbackReducer,
  asset,
  serviceTicket,
  orderManagement,
  OMJobPriorityReducer,
  OMJobSubCategoryReducer,
  OMBillingCycleReducer,
  OMSgstCgstReducer,
  OMIgstReducer,
  OMApprovalMatrixReducer,
  OMComparisonFactorReducer,
  OMJobMainCategoryReducer,
  OMPaymentTypeReducer,
  OMTermConditionReducer,
  OMGoodServiceReceiptConditionReducer, 
  OMPaymentMethodReducer,
  OMJobCompletionStatusReducer,
  OMJobOverallStatusReducer,
  OMJobReminderReducer
})

export default rootReducer
